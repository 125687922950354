import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import DefaultLayout from "/opt/build/repo/src/templates/cms.template.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="h1" components={components}>{`Oops (╯°□°)╯︵ ┻━┻`}</MDXTag>
      <MDXTag name="p" components={components}>{`Looks like something something went wrong with your payment, please contact us at `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "mailto:hello@bridgeschool.io"
        }}>{`hello@bridgeschool.io`}</MDXTag>{` and we'll get back to you ASAP.`}</MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      